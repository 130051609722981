const routes = [
  {
    path: "/eduzz",
    component: () => import("@/views/eduzz/Eduzz.vue"),
    meta: {
      menu: "dashboard",
      title: "Eduzz",
      roles: ["ADMIN"],
    },
  },
];

export default routes;
