<template>
  <div id="app">
    <vue-progress-bar></vue-progress-bar>
    <transition mode="out-in">
      <router-view v-if="!loading" />
      <v-app v-else>
        <v-main>
          <v-container fluid class="fill-height align-center justify-center">
            <v-progress-circular
              :width="5"
              indeterminate
              :color="dark ? 'white' : 'primary'"
              size="80"
            ></v-progress-circular>
          </v-container>
        </v-main>
      </v-app>
    </transition>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "App",

  data() {
    return { loading: true };
  },
  components: {},

  computed: {
    ...mapState({
      light: (state) => state.template.light,
      dark: (state) => state.template.dark,
    }),
    ...mapState("Usuario", {
      perfil: (state) => state.perfil,
    }),
  },

  async created() {
    try {
      const token = this.$ls.get("token");
      const token_messages = this.$ls.get("token_messages");

      if (token && token_messages) {
        await this.$store.dispatch("Usuario/getUsuario");
        await this.$store.dispatch("Usuario/checkUserM");

        // this.$echo.options.auth.headers.Authorization = token_messages;

        if (this.perfil != "ADMIN") {
          let routes = this.$router.options.routes;
          let route = routes.find((item) => item.meta.perfil === this.perfil);
          if (route) {
            this.$router.push({ path: route.path });
          }
        }

        if (this.$route.fullPath === "/login") {
          const last_route = this.$ls.get("last_route");

          if (last_route && last_route != "/" && last_route != "/login") {
            this.$router.push({
              path: last_route,
            });
          } else {
            let routes = this.$router.options.routes;

            let route = routes.find((item) => item.meta.perfil === this.perfil);

            if (route) {
              this.$router.push({ path: route.path });
            }
          }
        }
      } else {
        this.$router.isReady().then(() => {
          const current_route = this.$ls.get("current_route");

          if (
            current_route != "/login" &&
            current_route != "/co2" &&
            current_route != "politica-privacidade"
          ) {
            this.$router.push({ path: "/login" });
          }
        });
        // if (this.$route.fullPath != "/login") {
        //   this.$router.push({ path: "/Login" });
        // }
      }

      const template = this.$ls.get("template", "light");

      if (template) {
        if (template === "light") {
          this.$vuetify.theme.dark = false;
          this.$store.commit("UPDATE_TEMPLATE", { dark: false, light: true });
        } else {
          this.$vuetify.theme.dark = true;
          this.$store.commit("UPDATE_TEMPLATE", { dark: true, light: false });
        }
      } else {
        this.$ls.set("template", "light");
      }

      this.loading = false;
    } catch (error) {
      this.loading = false;
    }
  },
};
</script>

<style lang="scss">
html::-webkit-scrollbar {
  display: none;
}

.v-navigation-drawer__content::-webkit-scrollbar {
  display: none;
}

.theme--dark.v-sheet {
  color: #eeeeee !important;
}

.theme--dark.v-icon {
  color: #eeeeee !important;
}

.panel-gradient-bg {
  background: rgb(35, 45, 88);
  background: linear-gradient(
    180deg,
    rgba(35, 45, 88, 1) 0%,
    rgba(34, 57, 129, 1) 100%
  );
}

.panel-gradient-bg-reverse {
  background: rgb(35, 45, 88);
  background: linear-gradient(
    0deg,
    rgba(35, 45, 88, 1) 0%,
    rgba(34, 57, 129, 1) 100%
  );
}

.panel-gradient-bg-dark {
  background: rgb(15, 15, 15);
  background: linear-gradient(
    90deg,
    rgba(15, 15, 15, 1) 0%,
    rgba(33, 33, 33, 1) 50%,
    rgba(15, 15, 15, 1) 100%
  );
}

.pattern-bg {
  background-color: #eeeeee !important;
  // background-image: url("./assets/pattern.svg") !important;
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: 100%;
}

.pattern-bg-dark {
  background-color: #3a3a3a !important;
  // background-image: url("./assets/pattern_dark.svg") !important;
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: 100%;
}

.data-tables__row-click {
  tbody {
    tr {
      cursor: pointer;
    }
  }
}

.v-enter,
.v-leave-to {
  transform: translate3d(-30px, 0, 0);
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: all 0.8s;
}

.v-input input {
  height: 32px !important;
}

.v-overflow-btn .v-input__slot {
  border-top: none !important;
  border-width: 1px 0 !important;
  border-color: rgba(0, 0, 0, 0.42) !important;
}

.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(
    .v-input--has-state
  )
  > .v-input__control
  > .v-input__slot
  fieldset {
  color: rgba(0, 0, 0, 0.12) !important;
}

.v-btn--outlined {
  border-color: rgba(0, 0, 0, 0.12) !important;
}

.v-data-table-header-mobile {
  display: none;
}

.v-data-table {
  th {
    font-size: 0.7rem !important;
    height: 32px !important;
    text-transform: uppercase;
  }
  td {
    font-size: 0.68rem !important;
    height: 32px !important;
  }
  .v-data-table__mobile-table-row {
    display: flex !important;
    width: 100%;
    max-width: 100%;
    flex-wrap: wrap;
    margin-bottom: 15px !important;
    overflow: hidden;
    border-top: thin solid rgba(0, 0, 0, 0.12) !important;
    border-left: thin solid rgba(0, 0, 0, 0.12) !important;
    border-right: thin solid rgba(0, 0, 0, 0.12) !important;
    padding: 0;
    border-radius: 4px;
  }

  .v-data-table__mobile-row {
    flex: 1 auto;
    height: auto !important;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-height: 0px !important;
    padding: 8px !important;
    border: thin solid rgba(0, 0, 0, 0.12) !important;
    margin: -1px -1px 0 -1px;

    .v-data-table__mobile-row__header {
      font-size: 0.7rem;
    }

    .v-data-table__mobile-row__cell {
      font-size: 0.68rem;
      width: 100%;
      text-align: left;
    }

    &:last-child {
      // border-bottom: none !important;
      margin-bottom: 0px;
    }
  }
}

.v-text-field input,
.v-textarea textarea {
  font-size: 14px;
}

.container.bg_white {
  background-color: #ffffff !important;
  border-color: rgba(0, 0, 0, 0.12) !important;
  border-left: solid thin rgba(0, 0, 0, 0.12);
  border-right: solid thin rgba(0, 0, 0, 0.12);
}

.textarea-mobile {
  .v-input__slot {
    padding: 12px !important;
  }
  textarea {
    font-size: 12px !important;
    line-height: 1.5 !important;
    margin-top: 0 !important;
  }
}

.v-application--is-ltr
  .v-list-group--no-action
  > .v-list-group__items
  > .v-list-item {
  padding-left: 30px !important;
}

.v-list--nav .v-list-item:not(:last-child):not(:only-child),
.v-list--rounded .v-list-item:not(:last-child):not(:only-child) {
  margin-bottom: 2px !important;
}

.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 8px !important;
}
</style>
