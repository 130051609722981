const routes = [
  {
    path: "/leads",
    alias: "/",
    component: () => import("@/views/staff/leads/Leads.vue"),
    meta: {
      menu: "dashboard",
      title: "Leads",
      roles: ["STAFF"],
    },
  },
];

export default routes;
