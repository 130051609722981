import api from "./axios_service.js";

export async function login(usuario) {
  const response = await api.post("/login", {
    email: usuario.email,
    password: usuario.password,
    event_connection: usuario.event_connection,
  });
  return response.data;
}

export async function me() {
  const response = await api.post("/me");
  return response.data.result;
}
