const routes = [
  {
    path: "/networkings",
    component: () => import("@/views/networking/Networkings.vue"),
    meta: {
      menu: "dashboard",
      title: "Networking",
      roles: ["ADMIN"],
    },
  },
  {
    path: "/networkings/adicionar",
    component: () => import("@/views/networking/NovoNetworking.vue"),
    meta: {
      menu: "dashboard",
      title: "Adicionar Networking",
      roles: ["ADMIN"],
    },
  },
  {
    path: "/networkings/usuario/:userId",
    component: () => import("@/views/networking/UsuarioNetworkings.vue"),
    meta: {
      menu: "dashboard",
      title: "Networking",
      roles: ["ADMIN"],
    },
  },
];

export default routes;
