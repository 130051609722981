import Vue from "vue";
import router from "../router";
import axios from "axios";
import store from "@/store/index.js";
// import { i18n } from "../plugins/i18n";

const url = process.env.VUE_APP_API_URL;

const axiosInstance = axios.create({
  baseURL: url,
});

axiosInstance.interceptors.request.use(
  function (config) {
    const token = Vue.ls.get("token");
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// const last_route = Vue.ls.get("last_route");

// interceptor to catch errors
const errorInterceptor = (error) => {
  // all the error responses
  if (!error.response) {
    Vue.prototype.$toast.error("Sem acesso ao servidor");
  } else {
    switch (error.response.status) {
      case 400:
        Vue.prototype.$toast.error("Sem conexão");
        break;
      case 401: // authentication error, logout the user
        Vue.prototype.$toast.error("Não autorizado");

        store.dispatch("Usuario/deslogarUsuario");

        if (
          router.currentRoute.name != "Login" &&
          router.currentRoute.name != "co2" &&
          router.currentRoute.name != "politica-privacidade"
        ) {
          router.push({ path: "/login" });
        }

        break;
      case 403:
        Vue.prototype.$toast.error("Acesso não autorizado!");
        break;

      case 406:
        break;

      case 422:
        break;
      case 404: {
        break;
      }
      case 409: {
        break;
      }

      default:
        if (router.currentRoute.name != "Login") {
          router.push({ path: "/login" });
        }
        Vue.prototype.$toast.error("Sem conexão");
    }
  }

  return Promise.reject(error);
};

// Interceptor for responses
const responseInterceptor = (response) => {
  switch (response.status) {
    case 200:
      // yay!
      break;
    default:
    // default case
  }

  return response;
};

axiosInstance.interceptors.response.use(responseInterceptor, errorInterceptor);

export const api = {
  get(endpoint) {
    return axiosInstance.get(endpoint);
  },
  post(endpoint, body) {
    return axiosInstance.post(endpoint, body);
  },
  put(endpoint, body) {
    return axiosInstance.put(endpoint, body);
  },
  delete(endpoint) {
    return axiosInstance.delete(endpoint);
  },
};

export default api;
