const routes = [
  {
    path: "/relatorios",
    component: () => import("@/views/reports/Reports.vue"),
    meta: {
      menu: "dashboard",
      title: "Relatórios",
      roles: ["ADMIN", "STAFF"],
    },
  },
];

export default routes;
