import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Toast, { TYPE } from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueProgressBar from "vue-progressbar";
import { VueMaskDirective } from "v-mask";
import Storage from "vue-ls";
import * as filters from "./filters";
import VueCurrencyInput from "vue-currency-input";
import VueCurrencyFilter from "vue-currency-filter";
// import Echo from "laravel-echo";
// import * as PusherPushNotifications from "@pusher/push-notifications-web"

// window.Pusher = require("pusher-js");
// window.Pusher.logToConsole = false;

const progress_options = {
  color: "#75e289",
  failedColor: "#d64242",
  thickness: "4px",
};

const toastOptions = {
  toastDefaults: {
    // ToastOptions object for each type of toast
    [TYPE.SUCCESS]: {
      timeout: 4000,
      icon: {
        iconClass: "v-icon mdi mdi-check-circle",
      },
    },
    [TYPE.WARNING]: {
      timeout: 4000,
      icon: {
        iconClass: "v-icon mdi mdi-alert-circle",
      },
    },
    [TYPE.INFO]: {
      timeout: 4000,
      icon: {
        iconClass: "v-icon mdi mdi-alert-circle",
      },
    },
    [TYPE.ERROR]: {
      timeout: 4000,
      icon: {
        iconClass: "v-icon mdi mdi-alert",
      },
    },
  },
};

const storage_options = {
  namespace: "xbusiness__", // key prefix
  name: "ls", // name variable Vue.[ls] or this.[$ls],
  storage: "local", // storage name session, local, memory
};
Vue.use(Storage, storage_options);

// const production = process.env.NODE_ENV == "development" ? false : true;
// const token_messages = Vue.ls.get("token_messages");

// const echoInstance = new Echo({
//   broadcaster: "pusher",
//   key: "myappkey",
//   cluster: "mt1",
//   forceTLS: false,
//   enableStats: false,
//   host: production ? "api.softpar.inf.br/xbusiness-websocket" : "localhost",
//   wsHost: production ? "wss.softpar.inf.br" : "localhost",
//   wsPort: 6001,
//   wssPort: 6001,
//   enabledTransports: ["ws", "wss"],
//   encrypted: false,
//   authEndpoint: production
//     ? "https://api.softpar.inf.br/xbusiness-websocket/broadcasting/auth"
//     : "http://localhost:3001/broadcasting/auth",
//   auth: {
//     headers: {
//       Authorization: token_messages,
//     },
//   },
// });

// const beamsClient = new PusherPushNotifications.Client({
//   instanceId: '672fa719-1e70-42c3-b62f-c37f8642afd9',
// });

// Vue.prototype.$beam = beamsClient;
// Vue.prototype.$echo = echoInstance;

Vue.use(Toast, toastOptions);
Vue.use(VueProgressBar, progress_options);

Vue.directive("mask", VueMaskDirective);

Vue.config.productionTip = false;

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

const currency_filter_options = [
  {
    // default name 'currency'
    symbol: "",
    thousandsSeparator: ".",
    fractionCount: 2,
    fractionSeparator: ",",
    symbolSpacing: false,
  },
  {
    // default name 'currency_2'
    name: "guarani",
    symbol: "",
    thousandsSeparator: ".",
    fractionCount: 0,
    fractionSeparator: ",",
    symbolSpacing: false,
  },
  {
    // default name 'currency_3'
    name: "currency5digits",
    symbol: "",
    thousandsSeparator: ".",
    fractionCount: 5,
    fractionSeparator: ",",
    symbolSpacing: false,
  },
];

const currency_input_options = {
  /* see config reference */
  globalOptions: {
    autoDecimalMode: false,
    currency: null,
    distractionFree: false,
  },
};

Vue.use(VueCurrencyFilter, currency_filter_options);
Vue.use(VueCurrencyInput, currency_input_options);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
