import Vue from "vue";
import VueRouter from "vue-router";
import allRoutes from "./routes";
import store from "@/store/index.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    component: () => import("@/views/PaginaNaoEncontrada.vue"),
    meta: {
      title: "Pagina não Encontrada",
    },
  },
  {
    path: "/login",
    component: () => import("@/views/Login.vue"),
    meta: {
      title: "Login",
    },
  },
  {
    path: "/co2",
    component: () => import("@/views/co2/Co2.vue"),
    meta: {
      title: "Co2",
    },
  },
  {
    path: "/politica-privacidade",
    component: () => import("@/views/termos/PoliticaPrivacidade.vue"),
    meta: {
      title: "PoliticaPrivacidade",
    },
  },
  {
    path: "/",
    component: () => import("../layouts/Layout.vue"),
    meta: {
      login: true,
    },
    children: [...allRoutes],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} - XBUSINESS` || "XBUSINESS";
  if (to.matched.some((record) => record.meta.login)) {
    const token = Vue.ls.get("token");
    const role = Vue.ls.get("role");

    if (!token) {
      next("/login");
    } else {
      if (to.meta.roles) {
        if (!role || !to.meta.roles.some((item) => item === role)) {
          Vue.prototype.$toast.error("Acesso não autorizado!");
          store.dispatch("Usuario/deslogarUsuario");
        }
        next();
      } else {
        next();
      }
    }
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  if (
    to.name != "Login" &&
    to.name != "co2" &&
    to.name != "politica-privacidade"
  ) {
    Vue.ls.set("current_route", to.path);
  }
  Vue.ls.set("last_route", from.path);
});

export default router;
