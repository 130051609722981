import api from "./axios_service.js";

export async function login(body) {
  const response = await api.post("/login", body);
  return response.data;
}

export async function profile() {
  const response = await api.get("/profile");
  return response.data.result;
}
