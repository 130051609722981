import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import pt from "vuetify/lib/locale/pt";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#1D1C1C",
        secondary: "#909090",
        accent: "#EBEEF9",
        backgroundApp: "#f5f5f5",
        xbColor: "#efbd3c",
        error: "#ff5252",
        save: "#22547b",
        success: "#a5d64c",
        warning: "#ff8e00",
        timer: "#595959",
      },

      dark: {
        primary: "#232D58",
        secondary: "#3473B8",
        accent: "#EBEEF9",
        error: "#ff5252",
        info: "#22547b",
        success: "#a5d64c",
        warning: "#ff8e00",
      },
    },
  },
  lang: {
    locales: { pt },
    current: "pt",
  },
});
