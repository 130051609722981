const routes = [
  {
    path: "/home",
    alias: "/",
    component: () => import("@/views/home/Home.vue"),
    meta: {
      menu: "dashboard",
      title: "Home",
      roles: ["ADMIN"],
    },
  },
];

export default routes;
