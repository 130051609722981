import { login, profile } from "@/api/auth.js";
import { login as loginMessage } from "@/api/messages/auth.js";
import { me as meMessage } from "@/api/messages/auth.js";
import Vue from "vue";

const initialState = () => ({
  usuario: {},
  perfil: null,
  role: null,
});

const state = initialState();

const getters = {};

const actions = {
  getUsuario({ dispatch, commit }) {
    return profile()
      .then((response) => {
        commit("UPDATE_USUARIO", response);
        commit("UPDATE_PERFIL", response.nome);
        Vue.ls.set("role", response.role_name.descricao);
        commit("UPDATE_ROLE", response.role_name.descricao);
      })
      .catch((error) => {
        dispatch("deslogarUsuario");
        throw error;
      });
  },
  checkUserM({ dispatch }) {
    return meMessage().catch((error) => {
      dispatch("deslogarUsuario");
      throw error;
    });
  },
  logarUsuario(context, payload) {
    return login({
      email: payload.email,
      password: payload.senha,
      event_connection: payload.event_connection,
    })
      .then((response) => {
        Vue.ls.set("token", `Bearer ${response.token}`);
      })
      .catch((error) => {
        throw error;
      });
  },
  logaMessages(context, payload) {
    return loginMessage({
      email: payload.email,
      password: payload.senha,
      event_connection: payload.event_connection,
    })
      .then((response) => {
        Vue.ls.set("token_messages", `Bearer ${response.token}`);
      })
      .catch((error) => {
        throw error;
      });
  },
  deslogarUsuario(context) {
    Vue.ls.remove("token");
    Vue.ls.remove("token_messages");
    Vue.ls.remove("current_route");
    context.commit("RESET");
  },
  reset({ commit }) {
    commit("RESET");
  },
};

const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  UPDATE_USUARIO(state, payload) {
    state.usuario = Object.assign(state.usuario, payload);
  },
  UPDATE_USUARIOS(state, payload) {
    state.usuarios = payload;
  },
  UPDATE_PERFIL(state, payload) {
    state.perfil = payload;
  },
  UPDATE_ROLE(state, payload) {
    state.role = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
