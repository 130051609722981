import api from "../axios_service.js";

export async function all(filtros) {
  const response = await api.get(`/admin/posts${filtros ? filtros : ""}`);
  return response.data.result;
}

export async function one(id) {
  const response = await api.get(`/admin/posts/${id}`);
  return response.data.result;
}

export function putPost(id, post) {
  return api.post(`/admin/posts/${id}`, post);
}

export function postPost(post) {
  return api.post(`/admin/posts`, post);
}

export function like(post) {
  return api.post(`/admin/posts-curtidas`, post);
}

export function dislike(id) {
  return api.delete(`/admin/posts-curtidas/${id}`);
}
