const routes = [
  {
    path: "/notificacoes",
    component: () => import("@/views/feed/Feeds.vue"),
    meta: {
      menu: "dashboard",
      title: "Notificações",
      roles: ["ADMIN"],
    },
  },
  {
    path: "/notificacao/adicionar",
    component: () => import("@/views/feed/NovoFeed.vue"),
    meta: {
      menu: "dashboard",
      title: "Adicionar Notificação",
      roles: ["ADMIN"],
    },
  },
  {
    path: "/notificacao/:feedId",
    component: () => import("@/views/feed/Feed.vue"),
    meta: {
      menu: "dashboard",
      title: "Notificação",
      roles: ["ADMIN"],
    },
  },
];

export default routes;
