const routes = [
  {
    path: "/usuarios",
    component: () => import("@/views/usuarios/Usuarios.vue"),
    meta: {
      menu: "dashboard",
      title: "Usuarios",
      roles: ["ADMIN"],
    },
  },
  {
    path: "/usuarios/adicionar",
    component: () => import("@/views/usuarios/NovoUsuario.vue"),
    meta: {
      menu: "dashboard",
      title: "Adicionar Usuarios",
      roles: ["ADMIN"],
    },
  },
  {
    path: "/usuarios/importar",
    component: () => import("@/views/usuarios/components/UploadUsuarios.vue"),
    meta: {
      menu: "dashboard",
      title: "Importar Usuarios",
      roles: ["ADMIN"],
    },
  },
  {
    path: "/usuarios/:usuarioId",
    component: () => import("@/views/usuarios/Usuario.vue"),
    meta: {
      menu: "dashboard",
      title: "Usuário",
      roles: ["ADMIN"],
    },
  },
];

export default routes;
