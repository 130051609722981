const routes = [
  {
    path: "/loja",
    redirect: "/loja/produtos",
    meta: {
      menu: "dashboard",
      title: "Loja",
      roles: ["ADMIN", "MERCADO"],
    },
  },
  {
    path: "/loja/produtos",
    component: () => import("@/views/loja/produtos/ProdutosPage.vue"),
    meta: {
      menu: "dashboard",
      title: "Produtos",
      roles: ["ADMIN", "MERCADO"],
    },
  },
  {
    path: "/loja/produtos/adicionar",
    component: () => import("@/views/loja/produtos/NovoProduto.vue"),
    meta: {
      menu: "dashboard",
      title: "Novo produto",
      roles: ["ADMIN", "MERCADO"],
    },
  },
  {
    path: "/loja/produtos/:produtoId",
    component: () => import("@/views/loja/produtos/NovoProduto.vue"),
    meta: {
      menu: "dashboard",
      title: "Editar produto",
      roles: ["ADMIN", "MERCADO"],
    },
  },
  {
    path: "/loja/categorias",
    component: () => import("@/views/loja/categorias/CategoriasPage.vue"),
    meta: {
      menu: "dashboard",
      title: "Categorias",
      roles: ["ADMIN", "MERCADO"],
    },
  },
  {
    path: "/loja/categorias/adicionar",
    component: () => import("@/views/loja/categorias/NovaCategoria.vue"),
    meta: {
      menu: "dashboard",
      title: "Adicionar Categoria",
      roles: ["ADMIN", "MERCADO"],
    },
  },
  {
    path: "/loja/categorias/:categoriaId",
    component: () => import("@/views/loja/categorias/NovaCategoria.vue"),
    meta: {
      menu: "dashboard",
      title: "Categoria",
      roles: ["ADMIN", "MERCADO"],
    },
  },
  {
    path: "/loja/pedidos",
    component: () => import("@/views/loja/pedidos/PedidosPage.vue"),
    meta: {
      menu: "dashboard",
      title: "Pedidos",
      roles: ["ADMIN", "MERCADO"],
    },
  },
  {
    path: "/loja/relatorios",
    component: () => import("@/views/loja/RelatoriosPage.vue"),
    meta: {
      menu: "dashboard",
      title: "Relatórios",
      roles: ["ADMIN"],
    },
  },
];

export default routes;
