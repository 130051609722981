import { all as fetchPosts, putPost } from "@/api/admin/posts.js";

const initialState = () => ({
  posts: [],
});

const state = initialState();

const getters = {
  getPostsPendentes: (state) => {
    return state.posts.filter((post) => post.status == 0);
  },
  getPostsAprovados: (state) => {
    return state.posts.filter((post) => post.status == 1);
  },
  getPostsReprovados: (state) => {
    return state.posts.filter((post) => post.status == 2);
  },
};

const actions = {
  getPosts({ commit }, filtro) {
    return fetchPosts(`?status=${filtro}`)
      .then((response) => {
        commit("UPDATE_POSTS", response);
      })
      .catch((error) => {
        throw error;
      });
  },
  pushPost({ state }, post) {
    state.posts.push(post);
  },
  aprovarPost({ state }, post_id) {
    return putPost(post_id, { status: 1 })
      .then((response) => {
        if (response.status === 200) {
          let index = state.posts.findIndex((item) => item.id === post_id);

          if (index != -1) {
            state.posts[index].status = 1;
          }
        }
      })
      .catch((error) => {
        throw error;
      });
  },
  reprovarPost({ state }, post_id) {
    return putPost(post_id, { status: 2 })
      .then((response) => {
        if (response.status === 200) {
          let index = state.posts.findIndex((item) => item.id === post_id);

          if (index != -1) {
            state.posts[index].status = 2;
          }
        }
      })
      .catch((error) => {
        throw error;
      });
  },
  reset({ commit }) {
    commit("RESET");
  },
};

const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  UPDATE_POSTS(state, payload) {
    state.posts = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
