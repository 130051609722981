const routes = [
  {
    path: "/tags-groups",
    component: () => import("@/views/tagsGroups/TagsGroups.vue"),
    meta: {
      menu: "dashboard",
      title: "Tags Groups",
      roles: ["ADMIN"],
    },
  },
];

export default routes;
