const routes = [
  {
    path: "/palestras",
    component: () => import("@/views/palestras/PalestrasTab.vue"),
    meta: {
      menu: "dashboard",
      title: "Palestras",
      roles: ["ADMIN"],
    },
  },
  {
    path: "/palestras/adicionar",
    component: () => import("@/views/palestras/NovaPalestra.vue"),
    meta: {
      menu: "dashboard",
      title: "Adicionar Palestras",
      roles: ["ADMIN"],
    },
  },
  {
    path: "/palestras/:palestraId",
    component: () => import("@/views/palestras/Palestra.vue"),
    meta: {
      menu: "dashboard",
      title: "Palestras",
      roles: ["ADMIN"],
    },
  },
  {
    path: "/palestras-avaliacoes",
    component: () => import("@/views/palestras/PalestrasAvaliacoes.vue"),
    meta: {
      menu: "dashboard",
      title: "Avaliações de Palestras",
      roles: ["ADMIN"],
    },
  },
];

export default routes;
